@import './fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 18px;
}

/* There is a bug in radix-ui where tooltips can leak from the screen horizontally, changing radix's min-width solves the problem. */
[data-radix-popper-content-wrapper] {
  min-width: fit-content !important;
}

/* remove blue effect when clicking buttons on mobile devices */
* {
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.custom-placeholder-transition-disabled::placeholder {
  transition: opacity 0.075s ease-in-out;
  opacity: 0;
}

.custom-placeholder-transition-enabled:hover::placeholder,
.custom-placeholder-transition-enabled:focus::placeholder {
  opacity: 1;
}

/* remove default x button in input search */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/* bugfix safari, colors too light in input with disabled */
.bugfix-safari:disabled {
  color: #808080;
  -webkit-text-fill-color: #808080; /* fix bug in safari */
}

/* make scrollbar personalized */
.scrollbar-simple::-webkit-scrollbar {
  width: 9px;
}

.scrollbar-simple::-webkit-scrollbar-track {
  border: none;
  background: #f1f1f1;
}

.scrollbar-simple::-webkit-scrollbar-thumb {
  border: none;
  background: #d9d9d9;
}

.scrollbar-simple::-webkit-scrollbar-thumb:hover {
  border: none;
  background: #c9c9c9;
}
