@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-Extralight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-Book.ttf') format('truetype');
  font-weight: 350;
}

@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Pravaler Text';
  src: url('./fonts/PravalerText/PravalerText-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-Extralight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-Book.ttf') format('truetype');
  font-weight: 350;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Pravaler Display';
  src: url('./fonts/PravalerDisplay/PravalerDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk/SpaceGrotesk-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk/SpaceGrotesk-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk/SpaceGrotesk-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./fonts/SpaceGrotesk/SpaceGrotesk-Bold.woff') format('woff');
  font-weight: 700;
}
